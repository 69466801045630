import styled from 'styled-components';
import { SeamlessBgOne, SeamlessBgTwo } from '../../assets/svgs';

export const Container = styled.section`
	padding: 7.5rem 8.5rem;
	padding: 7.5rem clamp(2rem, -1.75rem + 12vw, 8.75rem);
	background: url(${SeamlessBgOne}) no-repeat;
	background-size: cover;

	> div {
		border-radius: 2rem;
		background: url(${SeamlessBgTwo}) no-repeat #4060ea;
		background-size: cover;
		text-align: center;
		padding: 7.5rem 6rem 5rem;
		padding: clamp(4.5rem, 2.8333rem + 5.3333vw, 7.5rem) clamp(1rem, -1.7778rem + 8.8889vw, 6rem) clamp(2rem, 0.3333rem + 5.3333vw, 5rem);
		margin: auto;
		position: relative;
		width: fit-content;
	}
	.logo {
		position: absolute;
		top: 0;
		left: 50%;
		background-color: #fff;
		border-radius: 50%;
		width: 9rem;
		height: 9rem;
		width: clamp(6rem, 4.3333rem + 5.3333vw, 9rem);
		height: clamp(6rem, 4.3333rem + 5.3333vw, 9rem);
		display: grid;
		place-items: center;
		transform: translate(-50%, -50%);

		svg {
			width: 74%;
			height: auto;
		}
	}
	h2 {
		color: #fff;
		font-weight: 700;
		line-height: 1.2;
		letter-spacing: -0.0625rem;
		font-size: 3rem;
		font-size: clamp(2rem, 1.4444rem + 1.7778vw, 3rem);
		margin: 0 auto 3.75rem;
		margin: 0 auto clamp(2rem, 1.0278rem + 3.1111vw, 3.75rem);
		max-width: 41ch;
	}
	button {
		color: #070d19;
		font-family: 'Rethink Sans', sans-serif;
		font-size: 1.25rem;
		font-weight: 700;
		line-height: 1.3;
		border-radius: 50px;
		background: #f2c94c;
		padding: 1.3125rem 1.75rem;
	}
`;
