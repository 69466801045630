import { Link } from 'react-router-dom';
import { Approutes } from './routes';

export const FAQ_QUESTIONS = [
	{
		question: 'What is Descinder?',
		answers: [
			'Descinder is dedicated to empowering early businesses (Start-ups & SMEs) by providing opportunities for talented early-career individuals (Interns & Fresh Graduates) from diverse educational institutions. These opportunities extend to both traditional onsite settings such as schools and universities, as well as virtual platforms offering code and online training.',
		],
		menu: [],
	},
	{
		question: 'Who can register on Descinder?',
		answers: [
			"Whether you're a business (start-up/SME) seeking talent or an individual (Intern or Fresh Graduate) looking for opportunities, you can easily get started on Descinder. Simply create an account, fill out your profile, and select a subscription plan to unlock the full benefits of the platform.",
		],
		menu: [],
	},
	{
		question: 'Can I change the Company I work for after starting my internship?',
		answers: [
			"After commencing your internship, changing the company you're working for is not feasible since you are already linked to that specific company where you're interning.",
		],
		menu: [],
	},
	{
		question: 'Do I need a degree to be eligible for graduate Internships?',
		answers: [
			'Our internship programs cater to both on-site and online certification-seeking interns and recent graduates from various educational institutions, encompassing traditional schools and online platforms like Coursera, Edx, Future Learn, and more. The eligibility criteria for participation are aligned with degree standards, ensuring a diverse and comprehensive pool of talent.',
		],
		menu: [],
	},
	{
		question: 'What kind of companies are available for Internships?',
		answers: [
			'A diverse array of companies, ranging from innovative startups to thriving SMEs, has converged on our platform. These companies represent various sectors of the economy, and their presence here is centered around the collaborative creation of projects and the streamlined assignment of tasks. The platform serves as a dynamic hub where businesses from different industries can come together, fostering a collaborative environment for project development and task allocation.',
		],
		menu: [],
	},
	{
		question: 'What are the eligibility criteria to qualify for an internship?',
		answers: [
			<>
				To access opportunities on our platform, you need to demonstrate that you have undergone or are currently undergoing learning
				facilitation. This could be through an on-site institution like a university or academy, or via online certification platforms such as
				Coursera, FutureLearn, Edx, etc. Proof of completion from a recognized institution unlocks opportunities on the platform.
				<br />
				<br />
				Upon creating an account and completing your profile registration, you'll be required to provide essential information, including your
				email address, contact number, password, applicant's avatar (optional), applicant's name, a brief introduction about yourself, applicant
				type (intern/fresh graduate), highest level of education, area of study, recent institution attended, and other relevant details.
			</>,
		],
		menu: [],
	},
	{
		question: 'What are the eligibility criteria to register my business?',
		answers: ['You need to be a registered company'],
		menu: [],
	},
	{
		question: 'What is the refund policy?',
		answers: [
			<>
				If descinder fails to find you an internship that you are happy with, we will refund your money in full. You can read more about the
				refund policy on our <Link to={Approutes.docs.refund_policy}>refund policy</Link> page
			</>,
		],
		menu: [],
	},
];
