import { useContext } from 'react';
import { Container } from './styles';
import { CloseMenu, Facebook, Instagram, Linkedin, LogoLight, Twitter } from '../../../assets/svgs';
import { ModalContext } from '../../../context';
// import GainExperience from './GainExperience';
import StartUp from './StartUp';

const Application = () => {
	const { modals, setModals } = useContext(ModalContext);

	return (
		<Container>
			<div className="modal">
				<CloseMenu tabIndex={0} onClick={() => setModals((prev) => ({ ...prev, showApplication: false }))} />

				<aside>
					<button
						className={modals.applicationType === 'start-up' ? 'active' : ''}
						onClick={() => setModals((prev) => ({ ...prev, applicationType: 'start-up' }))}
					>
						<span>START-UPS | INCUBATORS</span>
						<span>KICK-START IDEAS</span>
					</button>
					{/* <button
						className={modals.applicationType === 'gain-experience' ? 'active' : ''}
						onClick={() => setModals((prev) => ({ ...prev, applicationType: 'gain-experience' }))}
					>
						<span>FOR NEW GRADUATE & INTERNS</span>
						<span>Gain Experience</span>
					</button> */}

					<div>
						<div className="socials-con">
							<a href="https://twitter.com/descinder" target="_blank" rel="noreferrer">
								<Twitter />
							</a>
							<a href="facebook.com" target="_blank" rel="noreferrer">
								<Facebook />
							</a>
							<a href="https://www.instagram.com/descinder/" target="_blank" rel="noreferrer">
								<Instagram />
							</a>
							<a href="https://www.linkedin.com/company/descinder11/" target="_blank" rel="noreferrer">
								<Linkedin />
							</a>
						</div>
						<span>Visit Our Links to follow-up</span>

						<LogoLight />
					</div>
				</aside>
				<div className="main">
					<StartUp setModals={setModals} />
					{/* {modals.applicationType === 'start-up' ? <StartUp setModals={setModals} /> : null} */}
					{/* {modals.applicationType === 'gain-experience' ? <GainExperience setModals={setModals} /> : null} */}
				</div>
			</div>
			<div className="backdrop"></div>
		</Container>
	);
};

export default Application;
