import { HowItWorksIconOne, HowItWorksIconThree, HowItWorksIconTwo } from '../assets/svgs';

export const HOW_INTERNSHIP_WORKS = {
	description: 'Simple three (3) step process to get you started on your internship with various Start-Ups and SMEs across the globe.',
	workings: [
		{
			title: 'Create an Account',
			body:
				'To sign up as an intern or a recent graduate and commence your internship with various startups and SMEs on this platform, please provide your information to create and finalize your account registration for full access.',
			icon: <HowItWorksIconOne />,
		},
		{
			title: 'Await Profile Approval',
			body:
				'We appreciate your patience as we await approval from our administrative and compliance team. This is a crucial step for quality assurance and ensuring a smooth onboarding process for all interns.',
			icon: <HowItWorksIconTwo />,
		},
		{
			title: 'Commence Internship ',
			body:
				'You can now begin your internship once a startup/SME adds you to a project. The waiting time for project assignment is two weeks. If it takes longer, please feel free to email us to expedite the process.',
			icon: <HowItWorksIconThree />,
		},
	],
};

export const HOW_STARTUP_WORKS = {
	description: 'Simple three (3) step process to get your project started with our firm and start reaping the benefits of your product.',
	workings: [
		{
			title: 'Create a Profile',
			body:
				'To register as a startup/SME and start creating projects on our platform, please provide the required KYC information for a seamless process and full access to our business features.',
			icon: <HowItWorksIconOne />,
		},
		{
			title: 'Await Profile Approval',
			body:
				'We appreciate your patience as we await approval from our administrative and compliance team. This is a crucial step for quality assurance and ensuring a smooth onboarding process for all businesses.',
			icon: <HowItWorksIconTwo />,
		},
		{
			title: 'Kick Start Project',
			body:
				'You are now able to initiate projects within your profile and generate and delegate numerous tasks to your interns. This ensures the effective allocation and supervision of intern tasks throughout their internship lifecycle.',
			icon: <HowItWorksIconThree />,
		},
	],
};
