import { NavLink, Outlet } from 'react-router-dom';
import { Approutes } from '../../constants/routes';
import { Container, Nav } from './styles';

const navList = [
	{ title: 'terms of use', link: Approutes.docs.terms_of_use },
	{ title: 'core values', link: Approutes.docs.core_values },
	{ title: 'privacy policy', link: Approutes.docs.privacy_policy },
	{ title: 'code of conduct', link: Approutes.docs.code_of_conduct },
	{ title: 'refund policy', link: Approutes.docs.refund_policy },
	{ title: 'community guidelines', link: Approutes.docs.community_guidelines },
];

const DocsLayout = () => {
	return (
		<Container>
			<Nav>
				{navList.map((item) => (
					<NavLink key={item.title} to={item.link}>
						{item.title}
					</NavLink>
				))}
			</Nav>

			<Outlet />
		</Container>
	);
};
export default DocsLayout;
