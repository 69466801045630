import { Container } from './styles';
import { FaqMinus, FaqPlus } from '../../assets/svgs';
import { FAQ_QUESTIONS } from '../../constants';

const FAQ = () => {
	const toggleAnswer = (e) => {
		e.currentTarget.parentElement.classList.toggle('active');
	};

	return (
		<Container id="faq">
			<h3>FAQ</h3>
			<p>Get answers to the most popular questions our users have.</p>

			{FAQ_QUESTIONS.map((faq) => (
				<div className="faq-con">
					<div className="question" onClick={toggleAnswer}>
						<FaqMinus className="minus" />
						<FaqPlus className="plus" />
						{faq.question}
					</div>

					<div className="answer first">
						{faq.menu.length ? (
							<div className="control">
								{faq.menu?.[0] ? (
									<button
										onClick={(event) => {
											event.currentTarget.parentElement.parentElement.classList.remove('second');
											event.currentTarget.parentElement.parentElement.classList.add('first');
										}}
									>
										{faq.menu?.[0]}
									</button>
								) : null}
								{faq.menu?.[1] ? (
									<button
										onClick={(event) => {
											event.currentTarget.parentElement.parentElement.classList.remove('first');
											event.currentTarget.parentElement.parentElement.classList.add('second');
										}}
									>
										{faq.menu?.[1]}
									</button>
								) : null}
							</div>
						) : null}

						<div>
							{faq.answers.map((answer) => (
								<p>{answer}</p>
							))}
						</div>
					</div>
				</div>
			))}
		</Container>
	);
};

export default FAQ;
