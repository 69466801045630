import { useState } from 'react';
import { EmpoweringImage } from '../../assets/images';
import { ConnectingArrow } from '../../assets/svgs';
import { Button } from '../../ui';
import { Container } from './styles';

const navList = [
	{
		title: 'Create Project',
		body:
			'By integrating these skilled individuals into your projects, you gain access to new ideas and technical expertise that help you stay ahead in a competitive market.',
	},
	{ title: 'Create Tasks', body: 'Create tasks for your project by providing necessary information for assignment to resources.' },
	{ title: 'Assign Tasks', body: 'After creating projects and tasks, you can assign them to your chosen resources.' },
	{
		title: 'Approve Tasks',
		body: 'After tasks are assigned, resources perform them, and then log their reports. The business can then approve the completed tasks.',
	},
];

const EmpoweringSection = () => {
	const [state, setState] = useState(navList[0]);

	return (
		<Container>
			<div>
				<div>
					<h4>Start-ups & SMEs</h4>
					<h3>Boost Your Projects with Top Interns</h3>

					<div className="nav">
						{navList.map((item) => (
							<div key={item.title} className={item.title === state?.title ? 'active' : ''} onClick={() => setState(item)}>
								{item.title}
							</div>
						))}
					</div>

					<p>{state?.body}</p>
					<a href="https://app.descinder.com/signup/as-startup">
						<Button>GET STARTED</Button>
					</a>
				</div>
				<div className="img-con">
					<a href="https://app.descinder.com/signup/as-startup">
						<ConnectingArrow />
					</a>
					<img src={EmpoweringImage} alt="" loading="lazy" />
				</div>
			</div>
		</Container>
	);
};

export default EmpoweringSection;
