import { useContext, useState } from 'react';
import { Button, InputGroup } from '../../../ui';
import { Container } from './styles';
import { CloseMenu } from '../../../assets/svgs';
import { ModalContext } from '../../../context';
import { useNotify } from '../../../hooks';
import { axios } from '../../../library';
import { Apiurls } from '../../../constants';

const ConsultNow = () => {
	const notify = useNotify();
	const { setModals } = useContext(ModalContext);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		company_name: '',
		number: '',
		interest: '',
		email: '',
	});

	const handleChange = (event) => {
		const name = event.target.name || '';
		const value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleSubmit = async (event) => {
		event.preventDefault();

		setLoading(true);

		try {
			await axios.post(Apiurls.consult, {
				name: formData.company_name,
				phone: formData.number,
				email: formData.email,
				note: formData.interest,
			});
			notify({ message: 'Your message has been submitted!', variant: 'major', status: 'success' });
		} catch (error) {
			notify({ message: 'Unable to submit your message!', variant: 'major', status: 'error' });
		} finally {
			setModals((prev) => ({ ...prev, showConsult: false }));
			setLoading(false);
		}
	};

	return (
		<Container>
			<div className="modal">
				<CloseMenu tabIndex={0} onClick={() => setModals((prev) => ({ ...prev, showConsult: false }))} />

				<div className="header">
					<h3>Consult Now</h3>
					<p>Feel free to drop a consultation note to register a free first time consultation</p>
				</div>

				<form onSubmit={handleSubmit}>
					<div className="group">
						<InputGroup
							label="Individual / Company Name"
							placeholder="Enter Applicant Name"
							value={formData.company_name}
							onChange={handleChange}
							name="company_name"
							required
						/>
						<InputGroup
							label="Mobile Number"
							placeholder="Enter Mobile Number"
							value={formData.number}
							onChange={handleChange}
							name="number"
							type="tel"
							required
						/>
						<InputGroup
							label="Consultation Interest"
							placeholder="Drop a short note to register your interest (50 Words)"
							value={formData.interest}
							onChange={handleChange}
							name="interest"
							required
							variant="textarea"
						/>
						<InputGroup
							label="Email Address"
							placeholder="Enter Email Address"
							value={formData.email}
							onChange={handleChange}
							name="email"
							type="email"
							required
						/>
					</div>

					<div className="terms">
						<input type="checkbox" required />{' '}
						<span>
							By Continuing you agree to our{' '}
							<button type="button" onClick={() => setModals((prev) => ({ ...prev, showTerms: true }))}>
								Terms and Conditions
							</button>
						</span>
					</div>
					<Button type="submit" loading={loading}>
						Register Free Consultation
					</Button>
				</form>
			</div>
			<div className="backdrop"></div>
		</Container>
	);
};

export default ConsultNow;
