import styled from 'styled-components';
import { StyledButton } from '../../ui/Button/styles';
import { maxQuery } from '../../utilities';

export const Container = styled.section`
	padding: 5rem 8.5rem 5rem;
	padding: clamp(4.5rem, 4.234rem + 0.8511vw, 5rem) clamp(2rem, -1.75rem + 12vw, 8.75rem) clamp(4.5rem, 4.234rem + 0.8511vw, 5rem);

	h3 {
		color: #070d19;
		font-size: 3.125rem;
		font-size: clamp(2rem, 1.4016rem + 1.9149vw, 3.125rem);

		line-height: 130%;
		text-align: center;
	}
	> p {
		text-align: center;
		color: #333;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

		line-height: 150%;
	}

	> section {
		margin-top: 4.5rem;
		margin-top: clamp(2.25rem, 1.0532rem + 3.8298vw, 4.5rem);

		.controls {
			display: flex;
			justify-content: center;
			gap: 1.2rem;
			gap: clamp(0.5rem, 0.1277rem + 1.1915vw, 1.2rem);
			padding-bottom: 0.5rem;

			margin-bottom: 2rem;
			margin-bottom: clamp(1.5rem, 1.234rem + 0.8511vw, 2rem);

			${maxQuery('md')} {
				justify-content: flex-start;
				overflow: auto;
			}

			${StyledButton} {
				padding: 1rem 1.5rem;
				padding: clamp(0.8rem, 0.6rem + 0.64vw, 1rem) clamp(1rem, 0.734rem + 0.8511vw, 1.5rem);

				font-size: 1rem;
				font-size: clamp(0.75rem, 0.1818rem + 0.9091vw, 1rem);

				font-weight: 700;
				text-transform: uppercase;
				background-color: transparent;
				color: #070d19;
				border: 1.5px solid transparent;
				min-width: max-content;

				&.active,
				:hover {
					border: 1.5px solid ${({ theme }) => theme.colors.primary500};
					background: ${({ theme }) => theme.colors.primary500};
					color: ${({ theme }) => theme.colors.white};
				}
			}
		}
	}
	.all-projects-con {
		display: grid;
		margin: auto;
		max-width: 1800px;
	}
	.projects {
		grid-area: 1/1;
		display: grid;
		grid-gap: 1.5rem;
		grid-gap: clamp(1rem, -0.1364rem + 1.8182vw, 1.5rem);
		opacity: 0;
		transition: opacity 0.5s;
		grid-template-columns: 1fr 1fr;

		${maxQuery('lg')} {
			display: flex;
			flex-direction: column;
		}

		&.mobile {
			height: auto;
			grid-auto-rows: 1fr;

			> div:nth-child(2) {
				grid-area: 1/2/3/3;
				min-height: 15rem;
			}
			> div:nth-child(3) {
				grid-area: 2/1/4/2;
				min-height: 15rem;
			}
			.content-con {
				padding: 2rem 3rem;
				padding: 2rem clamp(1.5rem, 0.7021rem + 2.5532vw, 3rem);
			}
		}
		&.active {
			opacity: 1;
			transition: opacity 0.5s;
		}
	}
`;

export const Product = styled.div`
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	display: grid;
	background: #333;

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		max-width: 100%;
		transform: translate(-50%, -50%);
	}
	.content-con {
		margin-top: auto;
		position: relative;
		z-index: 2;
		padding: 5rem 3rem;
		padding: clamp(2rem, 0.4043rem + 5.1064vw, 5rem) clamp(1.5rem, 0.7021rem + 2.5532vw, 3rem);
	}
	.img-con {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		min-height: 100%;
		z-index: 1;

		::before {
			content: '';
			position: absolute;
			inset: 0;
			background: #000d46;
			z-index: 2;
			opacity: 0.6;
		}
	}

	b {
		color: #efb914;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);
		text-transform: uppercase;
	}
	h4 {
		color: #fff;
		font-size: 1.5rem;
		font-size: clamp(1rem, 0.734rem + 0.8511vw, 1.5rem);

		text-transform: uppercase;
		margin-bottom: 1rem;
		margin-bottom: clamp(0.5rem, 0.234rem + 0.8511vw, 1rem);
	}
	p {
		color: #fff;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

		line-height: 150%;
	}
`;
