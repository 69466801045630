import styled from 'styled-components';
import { maxQuery } from '../../utilities';
import { OneStopBg } from '../../assets/svgs';

export const Container = styled.section`
	padding: 7.5rem 8.5rem;
	padding: 7.5rem clamp(2rem, -1.75rem + 12vw, 8.75rem);
	background: url(${OneStopBg}) no-repeat center;
	background-size: cover;
	font-family: 'Rethink Sans', sans-serif;

	> div {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;
		gap: 3rem 3.5rem;
		gap: 3rem clamp(2rem, 1.2333rem + 2.4533vw, 3.38rem);
		max-width: 1700px;
		margin: auto;

		${maxQuery('lg')} {
			display: flex;
			flex-direction: column-reverse;
			text-align: center;
		}

		> div {
			overflow: hidden;
			max-width: 100%;
		}

		h4 {
			color: #4060ea;
			font-size: 1.75rem;
			font-size: clamp(1.25rem, 0.9722rem + 0.8889vw, 1.75rem);
			font-weight: 700;
			line-height: 1.25;
			letter-spacing: 0.0625rem;
			text-transform: uppercase;
		}

		h3 {
			color: #05060f;
			font-size: 3rem;
			font-size: clamp(2rem, 1.4444rem + 1.7778vw, 3rem);
			line-height: 1.2;
			text-transform: capitalize;
			margin: 1.5rem 0 2rem;
		}

		.nav {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			overflow: auto;
			color: #05060f99;
			line-height: 2;

			${maxQuery('lg')} {
				width: fit-content;
				max-width: 100%;
				margin: auto;
			}

			> div {
				border-radius: 50px;
				padding: 0.75rem 1.125rem;
				font-family: 'Rethink Sans', sans-serif;
				font-size: 0.875rem;
				font-weight: 700;
				cursor: pointer;
				min-width: max-content;

				&.active {
					background: #f2c94c;
					color: #05060f;
				}
			}
		}
		p {
			color: #05060f;
			font-size: 1.125rem;
			line-height: 1.7;
			margin: 1.75rem 0 2rem;
		}
		a {
			display: inline-block;
			width: fit-content;
		}

		.img-con {
			width: fit-content;
			max-width: 100%;
			position: relative;

			${maxQuery('lg')} {
				margin: auto;
			}

			> a {
				position: absolute;
				top: 2.5rem;
				right: 2.5rem;
			}
		}
		img {
			max-width: 100%;

			${maxQuery('lg')} {
				display: block;
			}
		}
	}
`;
