import { useRef, useEffect, useContext } from 'react';
// import { HeroUpLine, HeroFreshLine } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Container } from './styles';
import { ModalContext } from '../../../context';

const HeroSection = () => {
	const heroRef = useRef();
	const { setModals } = useContext(ModalContext);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				document.querySelector('header').classList.toggle('bright', !entry.isIntersecting);
			});
		});

		observer.observe(heroRef.current);

		return () => observer.disconnect();
	}, []);

	return (
		<Container ref={heroRef}>
			<div>
				<h1>
					We do things <span>differently...</span>
				</h1>
				<p>Fueling Start-Ups with Eager Interns and Graduates, to drive growth and empower Innovation.</p>

				<div>
					<a href="https://app.descinder.com/signup">
						<Button>GET STARTED</Button>
					</a>
					<Button onClick={() => setModals((prev) => ({ ...prev, showApplication: true, applicationType: 'start-up' }))} variant="secondary">
						INTRODUCTORY CALL
					</Button>
				</div>
			</div>

			<section>
				<div>
					<div>
						<big>1,000+</big>
						<h6>Internship completed</h6>
						<p>We have connected over 1,000 interns and companies</p>
					</div>
					<div>
						<big>50%</big>
						<h6>Increase quarterly</h6>
						<p>We are growing each day and delivering the best to our users</p>
					</div>
					<div>
						<big>25+</big>
						<h6>Countries served</h6>
						<p>Our product is been used in over 25 countries worldwide</p>
					</div>
				</div>
			</section>
		</Container>
	);
};

export default HeroSection;
