export const INTERNSHIP_BENEFITS = {
	title: 'Launch Your Career with Hands-On Experience and Growth Opportunities.',
	description: 'Explore a range of exciting opportunities to learn, grow, and gain practical experience',
	benefits: [
		{
			title: 'Creative Innovation',
			body:
				'Develop your analytical skills by working on real-world data challenges. Learn to interpret data, generate actionable insights, and drive business decisions.',
		},
		{
			title: 'Insightful Analytics',
			body:
				'Immerse yourself in projects that push the boundaries of design and creativity. Bring your unique ideas to life and stay ahead with the latest trends',
		},
		{
			title: 'Tech Development:',
			body:
				'Engage in hands-on software development. Enhance your programming skills, contribute to cutting-edge projects, and collaborate with experienced developers.',
		},
		{
			title: 'Engineering Solutions',
			body:
				'Participate in the engineering process, from brainstorming to execution. Apply your knowledge to build innovative solutions and see your ideas transform into practical products.',
		},
	],
};

export const STARTUP_BENEFITS = {
	title: 'Create innovative products with skilled interns',
	description: 'We bring a blend of industry experience and the right technical skills to deliver results.',
	benefits: [
		{
			title: 'Product Design',
			body:
				'With their creative thinking and ability to stay updated with the latest design trends, our young minds bring fresh air to your design process.',
		},
		{
			title: 'Data & Business Analytics',
			body:
				'Fresh talent contributes new perspectives, energy, and skills, enhancing your data analysis with fresh approaches and innovative insights.',
		},
		{
			title: 'Software Development',
			body:
				'Interns bring innovation and current technical skills, helping start-ups build cutting-edge products and stay ahead in the fast-paced tech industry.',
		},
		{
			title: 'Product Engineering',
			body:
				'With a blend of creativity and the latest engineering knowledge, our interns support you in creating innovative solutions for their target market.',
		},
	],
};
