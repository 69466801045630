import styled from 'styled-components';

export const Container = styled.div`
	label {
		color: #494848;
		font-size: 1rem;
		font-weight: 500;
		line-height: 120%;
		margin-bottom: 0.5rem;
		display: block;
	}

	input,
	textarea {
		border: 1px solid #dadada;
		display: block;
		width: 100%;
		border-radius: 0.1875rem;
		background: #fff;
		padding: 1rem 1.5rem;
		font-family: inherit;
		font-size: 1rem;
		line-height: 130%;
	}
	textarea {
		resize: vertical;
	}
`;
