import { ConnectingIllustration1, ConnectingIllustration2, ConnectingIllustration3, ConnectingIllustration4 } from '../../assets/images';
import { ConnectingArrow, Figma, Framer, Node, Python, React, Sketch } from '../../assets/svgs';
import { Container } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const ConnectingSection = () => {
	return (
		<Container id="about">
			<h3>
				Foster <span className="blue">Innovation and</span> Drive Success <br /> with Fresh Talent and <span className="yellow">Expertise</span>
			</h3>

			<div>
				<div className="box">
					<div className="heading">
						<div>
							<big>Leverage Innovation and Expertise to Achieve Results</big>
							<p>
								We connect you with interns who bring fresh perspectives and up-to-date skills, driving innovation and enhancing your team’s
								capabilities.
							</p>
						</div>
						<a href="https://app.descinder.com/signup">
							<ConnectingArrow />
						</a>
					</div>

					<Swiper
						speed={700}
						modules={[Autoplay]}
						spaceBetween={12}
						slidesPerView={'auto'}
						autoplay={{ delay: 2500, reverseDirection: true }}
						className="startup-perks"
					>
						<SwiperSlide>
							<img src={ConnectingIllustration1} alt="" loading="lazy" />
							<span>3/6 MONTHS EXPERIENCES</span>
						</SwiperSlide>
						<SwiperSlide>
							<img src={ConnectingIllustration2} alt="" />
							<span>ADD SUPERVISOR / MENTOR</span>
						</SwiperSlide>
						<SwiperSlide>
							<img src={ConnectingIllustration3} alt="" />
							<span>LOG TIMESHEET</span>
						</SwiperSlide>
						<SwiperSlide>
							<img src={ConnectingIllustration4} alt="" />
							<span>RESOURCES PACKAGES</span>
						</SwiperSlide>
					</Swiper>

					{/* <div>
								<img src={} alt="" />
								<span>TASKS ASSIGNMENTS</span>
							</div> */}
				</div>
				<div className="box diverse">
					<big>Unlock Growth and Gain Real-World Experience</big>

					<div className="skills-con">
						{SKILLS.map((row, index) => (
							<Swiper
								key={index}
								speed={7000}
								modules={[Autoplay]}
								loop={true}
								spaceBetween={16}
								slidesPerView={'auto'}
								autoplay={{ delay: 1000, reverseDirection: index === 1 }}
							>
								{row.map((skill) => (
									<SwiperSlide className="skill" key={skill.name}>
										{skill.icon}

										<div>
											<b>{skill.name}</b>
											<span>{skill.role}</span>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						))}
					</div>
				</div>
			</div>
		</Container>
	);
};

export default ConnectingSection;

const SKILLS = [
	[
		{
			name: 'Oluwaseyi Adetuyi',
			role: 'FrontEnd Developer',
			icon: <React />,
		},
		{
			name: 'David Ehigiator',
			role: 'Dev Lead',
			icon: <Node />,
		},
		{
			name: 'Frank Chujor',
			role: 'Program Manager',
			icon: <Sketch />,
		},
		{
			name: 'Ridwan Yusuf',
			role: 'Backend Lead',
			icon: <Python />,
		},
		{
			name: 'Adewole Ampitan',
			role: 'Project Manager',
			icon: <Framer />,
		},
		{
			name: 'Adedeji Ampitan',
			role: 'Senior Ux Design ',
			icon: <Figma />,
		},
	],
	[
		{
			name: 'David Ehigiator',
			role: 'Dev Lead',
			icon: <Node />,
		},
		{
			name: 'Frank Chujor',
			role: 'Program Manager',
			icon: <Sketch />,
		},
		{
			name: 'Ridwan Yusuf',
			role: 'Backend Lead',
			icon: <Python />,
		},
		{
			name: 'Adewole Ampitan',
			role: 'Project Manager',
			icon: <Framer />,
		},
		{
			name: 'Adedeji Ampitan',
			role: 'Senior Ux Design ',
			icon: <Figma />,
		},
		{
			name: 'Oluwaseyi Adetuyi',
			role: 'FrontEnd Developer',
			icon: <React />,
		},
	],
	[
		{
			name: 'Frank Chujor',
			role: 'Program Manager',
			icon: <Sketch />,
		},
		{
			name: 'Ridwan Yusuf',
			role: 'Backend Lead',
			icon: <Python />,
		},
		{
			name: 'Adewole Ampitan',
			role: 'Project Manager',
			icon: <Framer />,
		},
		{
			name: 'Adedeji Ampitan',
			role: 'Senior Ux Design ',
			icon: <Figma />,
		},
		{
			name: 'Oluwaseyi Adetuyi',
			role: 'FrontEnd Developer',
			icon: <React />,
		},
		{
			name: 'David Ehigiator',
			role: 'Dev Lead',
			icon: <Node />,
		},
	],
];
