import styled from 'styled-components';

export const Container = styled.section`
	padding: 5rem 8.5rem 5rem;
	padding: clamp(4.5rem, 4.234rem + 0.8511vw, 5rem) clamp(2rem, -1.75rem + 12vw, 8.75rem) clamp(4.5rem, 4.234rem + 0.8511vw, 5rem);

	> span {
		color: #4060ea;
		text-align: center;
		display: block;
	}
	h3 {
		color: #070d19;
		font-size: 3.125rem;
		font-size: clamp(2rem, 1.4016rem + 1.9149vw, 3.125rem);

		line-height: 130%;
		text-align: center;
	}
	> p {
		text-align: center;
		color: #333;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);
		line-height: 150%;
	}

	.values {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
		text-align: center;
		gap: 1rem;
		gap: clamp(1rem, -0.1111rem + 3.5556vw, 3rem) clamp(1rem, -0.6667rem + 5.3333vw, 4rem);
		margin-top: 3.25rem;

		> div {
			max-width: 370px;
			margin: auto;
		}

		b {
			display: block;
			font-size: 1.375rem;
			font-size: clamp(1.2rem, 1.1028rem + 0.3111vw, 1.375rem);
			font-weight: 700;
			line-height: 120%;
			margin: 1rem 0;
			text-transform: uppercase;
		}
		p {
			font-size: 1.125rem;
			font-size: clamp(1rem, 0.9306rem + 0.2222vw, 1.125rem);
		}
	}
`;
