import { useEffect, useState } from 'react';
import { FreshTalentStar, TalentFour, TalentOne, TalentThree, TalentTwo } from '../../assets/svgs';
import { Container, Talent } from './styles';
import { useRef } from 'react';
import { Button } from '../../ui';
import { INTERNSHIP_BENEFITS, STARTUP_BENEFITS } from '../../constants';

const Benefits = () => {
	const talentTwo = useRef();
	const talentFour = useRef();

	const [control, setControl] = useState('startup');

	const BENEFITS = control === 'startup' ? STARTUP_BENEFITS : INTERNSHIP_BENEFITS;

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add('in-view');
						observer.unobserve(entry.target);
					}
				});
			},
			{ rootMargin: '0px 0px -300px 0px' }
		);

		observer.observe(talentTwo.current);
		observer.observe(talentFour.current);

		return () => observer.disconnect();
	}, []);

	return (
		<Container id="why">
			<div className="controls">
				<Button className={control === 'startup' ? 'active' : ''} onClick={() => setControl('startup')}>
					Start-up & SMEs
				</Button>
				<Button className={control === 'intern' ? 'active' : ''} onClick={() => setControl('intern')}>
					Interns & Graduates
				</Button>
			</div>

			<h2>{BENEFITS.title}</h2>
			<p>{BENEFITS.description}</p>

			<div className="talents-con">
				<Talent>
					<TalentOne />
					<b>01</b>
					<h4>{BENEFITS.benefits[0].title}</h4>
					<p>{BENEFITS.benefits[0].body}</p>
				</Talent>

				<Talent ref={talentTwo}>
					<TalentTwo />
					<b>02</b>
					<h4>{BENEFITS.benefits[1].title}</h4>
					<p>{BENEFITS.benefits[1].body}</p>
				</Talent>

				<Talent>
					<TalentThree />
					<b>03</b>
					<h4>{BENEFITS.benefits[2].title}</h4>
					<p>{BENEFITS.benefits[2].body}</p>
				</Talent>

				<Talent ref={talentFour}>
					<TalentFour />
					<b>04</b>
					<h3>{BENEFITS.benefits[3].title}</h3>
					<p>{BENEFITS.benefits[3].body}</p>
				</Talent>
			</div>

			<FreshTalentStar className="bg" />
		</Container>
	);
};

export default Benefits;
