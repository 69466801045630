import styled from 'styled-components';
import { maxQuery } from '../../../utilities';
import { StyledButton } from '../../../ui/Button/styles';

export const Container = styled.div`
	position: fixed;
	inset: 0;
	z-index: 99;
	display: grid;
	place-items: center;

	.modal {
		border-radius: 0.25rem;
		background: #fff;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);
		padding: 3rem 4rem;
		padding: clamp(2rem, 1.4444rem + 1.7778vw, 3rem) clamp(1rem, -0.6667rem + 5.3333vw, 4rem);

		position: relative;
		z-index: 2;
		width: calc(100vw - 4rem);
		width: calc(100vw - clamp(2rem, 0.8889rem + 3.5556vw, 4rem));

		max-width: 820px;
		height: calc(100vh - 4rem);
		height: calc(100vh - clamp(2rem, 0.8889rem + 3.5556vw, 4rem));

		max-height: 680px;
		overflow: auto;

		> svg {
			position: absolute;
			right: 1rem;
			top: 1rem;
			width: 1.5rem;
			width: clamp(1.3rem, 1.1889rem + 0.3556vw, 1.5rem);

			cursor: pointer;

			:active {
				outline: none;
			}

			path {
				fill: #000;
			}
		}
		.header {
			text-align: center;
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 1.5rem;

			h3 {
				color: #070d19;
				font-size: 2rem;
				font-size: clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);

				margin-bottom: 0.5rem;
			}
			> p {
				color: #070d19;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

				line-height: 135%;
			}
		}

		form {
			margin-top: 3.5rem;

			.group {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1rem;

				${maxQuery('md')} {
					grid-template-columns: 1fr;
				}
			}
			.terms {
				display: flex;
				gap: 0.5rem;
				color: #1e1e1e;
				font-size: 0.75rem;
				line-height: 160%;
				margin: 1rem 0 1.5rem;

				button {
					color: #4060ea;
					font-weight: 600;
					background-color: transparent;
				}
			}
		}
		${StyledButton} {
			${maxQuery('md')} {
				width: 100%;
				justify-content: center;
			}
		}
	}
	.backdrop {
		opacity: 0.82;
		background: #000;
		position: absolute;
		inset: 0;
	}
`;
