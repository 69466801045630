import styled from 'styled-components';
import { maxQuery } from '../../utilities';

export const Container = styled.section`
	padding: 7.5rem 8.5rem;
	padding: 7.5rem clamp(2rem, -1.75rem + 12vw, 8.75rem);
	background: #f2f4fd;
	font-family: 'Rethink Sans', sans-serif;

	h3 {
		color: #05060f;
		font-size: 3rem;
		font-size: clamp(2rem, 1.4444rem + 1.7778vw, 3rem);
		line-height: 1.3;
		text-align: center;
		margin-bottom: 3.89rem;

		${maxQuery('sm')} {
			br {
				display: none;
			}
		}
	}

	> div {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 2rem 4rem;
		gap: 2rem clamp(2rem, 0.8889rem + 3.5556vw, 4rem);
		max-width: 1700px;
		margin: auto;

		${maxQuery('lg')} {
			grid-template-columns: 1fr;
		}

		> div {
			border-radius: 1.125rem;
			text-align: center;
			padding: 4rem 2rem 3.77rem;
			padding: clamp(2rem, 0.8889rem + 3.5556vw, 4rem) clamp(1rem, 0.4444rem + 1.7778vw, 2rem) clamp(2rem, 1.0167rem + 3.1467vw, 3.77rem);

			:nth-child(1) {
				color: #fff;
				background: #4060ea;
			}

			:nth-child(2) {
				background: #f2c94c;
				color: #05060f;

				button {
					border-color: #05060f;
				}
			}

			span {
				font-family: 'Nanum Pen Script', cursive;
				font-size: 1.75rem;
				font-size: clamp(1.25rem, 0.9722rem + 0.8889vw, 1.75rem);
				line-height: 1.2;
				letter-spacing: 0.0625rem;
				text-transform: uppercase;
				opacity: 0.6;
			}
			big {
				font-size: 2.5rem;
				font-size: clamp(1.75rem, 1.3333rem + 1.3333vw, 2.5rem);
				font-weight: 700;
				line-height: 1.2;
				margin: 0.87rem 0 1.94rem;
				display: block;
			}
			button {
				font-size: 1rem;
				font-size: clamp(0.875rem, 0.8056rem + 0.2222vw, 1rem);
				font-weight: 700;
				line-height: 1.25;
				text-transform: uppercase;
				border-radius: 50px;
				border: 2px solid #fff;
				padding: 0.81rem 2rem;
				color: inherit;
				background-color: transparent;
			}
		}
	}
`;
