import { ValueFive, ValueFour, ValueOne, ValueSix, ValueThree, ValueTwo } from '../../../assets/svgs';
import { Container } from './styles';

const CoreValues = () => {
	return (
		<Container>
			<span>Our Core Values</span>
			<h3>
				We're an ambitious team with <br /> a shared vision
			</h3>
			<p>Our Values Unite us in the Pursuit of Descinder's Vision.</p>

			<div className="values">
				<div>
					<ValueOne />
					<b>Innovation</b>
					<p>At Descinder, we encourage creativity and forward-thinking to drive innovation.</p>
				</div>
				<div>
					<ValueTwo />
					<b>Excellence</b>
					<p>We embrace excellence as our standard, continuously raising the bar for quality and achievement</p>
				</div>
				<div>
					<ValueThree />
					<b>Collaboration</b>
					<p>We work together with start-ups and SMEs to achieve mutual growth.</p>
				</div>
				<div>
					<ValueFour />
					<b>Drive</b>
					<p>We pursue goals with determination and a commitment to success.</p>
				</div>
				<div>
					<ValueFive />
					<b>Empowerment</b>
					<p>We provide opportunities that empower interns and graduates to excel.</p>
				</div>
				<div>
					<ValueSix />
					<b>Care</b>
					<p>At Descinder, we cultivate a supportive space for professional and personal development.</p>
				</div>
			</div>
		</Container>
	);
};
export default CoreValues;
