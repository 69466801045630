import styled from 'styled-components';
import { HowItWorksBgOne, HowItWorksBgTwo } from '../../assets/svgs';
import { maxQuery } from '../../utilities';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.section`
	padding: 3rem 8.5rem 6rem;
	padding: 3rem clamp(2rem, -1.75rem + 12vw, 8.75rem) 6rem;

	background: url(${HowItWorksBgOne}) no-repeat left bottom, url(${HowItWorksBgTwo}) no-repeat right top #fefcf5;
	background-size: cover;
	text-align: center;

	${maxQuery('lg')} {
		background: #fefcf5;
	}

	.controls {
		display: flex;
		width: fit-content;
		margin: 0 auto 2.25rem;
		border-radius: 12.5rem;
		background: #f2f4fd;
		flex-wrap: wrap;
		justify-content: center;

		${maxQuery(360)} {
			flex-direction: column;
			justify-content: center;
			background-color: transparent;
		}

		${StyledButton} {
			border-radius: 12.5rem;
			font-family: 'Nanum Pen Script', cursive;
			font-size: 1.35rem;
			font-size: clamp(1rem, 0.65rem + 1.4vw, 1.35rem);
			font-weight: 400;
			line-height: 1.3;
			justify-content: center;
			padding: 1rem 2.5rem;
			padding: clamp(0.8rem, 0.6rem + 0.64vw, 1rem) clamp(1rem, 0.1667rem + 2.6667vw, 2.5rem);

			:not(.active) {
				color: #4060ea;
				border-color: transparent;
				background-color: #f2f4fd;
			}
		}
	}

	h3 {
		color: #070d19;
		font-size: 3.125rem;
		font-size: clamp(2rem, 1.4016rem + 1.9149vw, 3.125rem);

		line-height: 130%;
	}
	> p {
		margin: auto;
		max-width: 63ch;
	}
	p {
		color: #333;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

		line-height: 150%;
	}

	.content {
		display: flex;
		justify-content: space-between;
		margin-top: 6rem;
		gap: 5rem 5rem;
		gap: 5rem clamp(1rem, -8.0909rem + 14.5455vw, 5rem);

		${maxQuery('lg')} {
			flex-direction: column;
		}

		h5 {
			color: #070d19;
			font-size: 1.3125rem;
			line-height: 150%;
			margin-bottom: 0.5rem;
			position: relative;
			z-index: 1;
		}

		big {
			color: #a8d5ff;
			font-size: 12.875rem;
			font-size: clamp(9.0625rem, 7.0346rem + 6.4894vw, 12.875rem);

			font-weight: 700;
			display: block;
			transform: translate(1.3rem, 0.6rem);
		}

		svg {
			transform: translateX(-120%);
		}
		> div:nth-child(2) big {
			color: #ffb8af;
		}
		> div:nth-child(3) big {
			color: #fedc89;
		}
	}
`;
