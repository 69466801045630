import { Container } from './styles';

const values = [
	{ title: 'Dynamism', body: 'Embrace change and adaptability for continuous improvement.' },
	{ title: 'Empowerment', body: 'Providing opportunities that empower interns and graduates to excel.' },
	{ title: 'Synergy', body: 'Foster collaboration and teamwork for shared success.' },
	{ title: 'Collaboration', body: 'Work together with start-ups and SMEs to achieve mutual growth.' },
	{ title: 'Innovation', body: 'Encourage creativity and forward-thinking to drive innovation.' },
	{ title: 'Nurturing', body: 'Cultivate a supportive environment for professional and personal development.' },
	{ title: 'Drive', body: 'Pursue goals with determination and a commitment to success.' },
	{ title: 'Entrepreneurship', body: 'Instil an entrepreneurial spirit in interns and graduates.' },
	{ title: 'Results-Oriented ', body: 'Focus on tangible outcomes and measurable impact.' },
];

const CoreValues = () => {
	return (
		<Container>
			<header>
				<h1>Core Values</h1>
				<p>Last updated August 2024</p>
			</header>

			<section>
				{values.map((value) => (
					<div key={value.title}>
						<h4>{value.title}</h4>
						<p>{value.body}</p>
					</div>
				))}
			</section>
		</Container>
	);
};

export default CoreValues;
