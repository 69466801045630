import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
	from {
		transform: translate(-50%, -50%) rotate(0deg)
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg)
	}
`;

export const StyledButton = styled.button`
	border-radius: 50px;

	padding: 1rem 2.5rem;
	padding: clamp(0.8rem, 0.6rem + 0.64vw, 1rem) clamp(1.8rem, 1.1rem + 2.24vw, 2.5rem);

	font-size: 1rem;
	font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

	font-weight: 600;
	line-height: 175%;
	display: flex;
	align-items: center;
	position: relative;
	border: 1.5px solid ${({ theme }) => theme.colors.primary500};

	:disabled {
		opacity: ${({ $loading }) => ($loading ? 1 : 0.5)};

		cursor: not-allowed;
	}

	/* Variant start */
	${({ $variant }) =>
		$variant === 'primary' &&
		css`
			background: ${({ theme }) => theme.colors.primary500};
			color: ${({ theme }) => theme.colors.white};

			:hover {
			}
			:focus {
			}
		`};
	${({ $variant }) =>
		$variant === 'secondary' &&
		css`
			color: ${({ theme }) => theme.colors.primary500};

			background: transparent;

			:hover {
			}
			:focus {
			}

			path {
				stroke: ${({ theme }) => theme.colors.primary500};
			}
		`};
	/* Variant end */

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: ${rotate} 1s infinite linear;
	}
`;
