import { SeamlessLogo } from '../../assets/svgs';
import { Container } from './styles';

const SeamlessSection = () => {
	return (
		<Container>
			<div>
				<div className="logo">
					<SeamlessLogo />
				</div>
				<h2>"Get access to talented interns and boost your projects effortlessly!"</h2>
				<a href="https://app.descinder.com/signup">
					<button>Get Started Now</button>
				</a>
			</div>
		</Container>
	);
};

export default SeamlessSection;
