import { useState } from 'react';
import { Button, InputGroup } from '../../../ui';
import { axios } from '../../../library';
import { Apiurls } from '../../../constants';
import { useNotify } from '../../../hooks';

const StartUp = ({ setModals }) => {
	const notify = useNotify();
	const [stage, setStage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		company_name: '',
		company_phone: '',
		company_address: '',
		company_email: '',
		date: '',
		time: '15:00',
	});

	const handleChange = (event) => {
		let name = event.target.name || '';
		let value = event.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (stage === 1) {
			setStage(2);
			return;
		}

		setLoading(true);

		try {
			await axios.post(Apiurls.startup, formData);
			notify({ message: 'Your message has been submitted!', variant: 'major', status: 'success' });
		} catch (error) {
			notify({ message: 'Unable to submit your message!', variant: 'major', status: 'error' });
		} finally {
			setStage(1);
			setLoading(false);
			setModals((prev) => ({ ...prev, showApplication: false }));
		}
	};

	return stage === 1 ? (
		<>
			<div className="header">
				<h3>KICK-START IDEAS</h3>
				<p>Do you need resources for your start-up & Incubators for your project</p>
			</div>

			<form onSubmit={handleSubmit}>
				<div className="group">
					<InputGroup
						label="Company Name"
						placeholder="Enter Company Name"
						value={formData.company_name}
						onChange={handleChange}
						name="company_name"
						required
					/>
					<InputGroup
						label="Company Mobile Number"
						placeholder="Enter Company Mobile Number"
						value={formData.company_phone}
						onChange={handleChange}
						name="company_phone"
						type="tel"
						required
					/>
					<InputGroup
						label="Company Address (Optional)"
						placeholder="Enter Company Address"
						value={formData.company_address}
						onChange={handleChange}
						name="company_address"
						variant="textarea"
					/>
					<InputGroup
						label="Company Email Address"
						placeholder="Enter Company Email Address"
						value={formData.company_email}
						onChange={handleChange}
						name="company_email"
						type="email"
						required
					/>
				</div>

				<div className="terms">
					<input type="checkbox" required />{' '}
					<span>
						By Continuing you agree to our{' '}
						<button type="button" onClick={() => setModals((prev) => ({ ...prev, showTerms: true }))}>
							Terms and Conditions
						</button>
					</span>
				</div>
				<Button type="submit">Set-Up Introductory Call</Button>
			</form>
		</>
	) : (
		<>
			<div className="header">
				<h3>Set-up Start Introductory Call</h3>
				<p>Provide date and time to set-up introductory call.</p>
			</div>

			<form className="introductory-form" onSubmit={handleSubmit}>
				<div className="group">
					<InputGroup
						label="Choose Date"
						placeholder="Select Date "
						value={formData.date}
						onChange={handleChange}
						name="date"
						type="date"
						min={new Date().toISOString().split('T')[0]}
						required
					/>
					<InputGroup
						label="Choose Time"
						placeholder="Select TIme "
						value={formData.time}
						onChange={handleChange}
						name="time"
						type="time"
						min="15:00"
						max="17:30"
						required
					/>
				</div>

				<div className="btn-group">
					<Button type="button" variant="secondary" onClick={() => setStage(1)}>
						Go Back
					</Button>
					<Button type="submit" loading={loading}>
						Submit
					</Button>
				</div>
			</form>
		</>
	);
};

export default StartUp;
