const colors = {
	// Primary
	primary500: '#4060EA',

	// Secondary
	secondary500: '#F2C94C',
	secondary700: '#EFB914',

	// Success
	success50: '#ECFDF5',
	success500: '#10B981',

	// Warning
	warning50: '#FFFBEB',
	warning500: '#CE6C47',

	// Error
	error50: '#FEE1E5',
	error500: '#F71735',

	// Shades
	black: '#000000',
	white: '#ffffff',
	grey: '#C7C7C7',
};
const breakpoints = {
	xs: 450,
	sm: 600,
	md: 800,
	lg: 1000,
	xl: 1200,
};

const theme = {
	colors,
	breakpoints,
};
export default theme;
