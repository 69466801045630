import { toast } from 'react-toastify';
import { Notification } from '../ui';
import { PropTypes } from 'prop-types';

const useNotify = () => {
	const notify = (args) => {
		const { message = '', status = 'info', variant = 'major', toastOptions = {} } = args;

		toast(<Notification message={message} variant={variant} status={status} />, toastOptions);
	};

	return notify;
};

useNotify.propTypes = {
	message: PropTypes.string,
	variant: PropTypes.oneOf(['major', 'minor']),
	status: PropTypes.oneOf(['info', 'success', 'error']),
	toastOptions: PropTypes.object,
};

export default useNotify;
