import { NotificationCoolEmote, NotificationInfo, NotificationCheckmark } from '../../assets/svgs';
import { MajorContainer, MinorContainer } from './styles';
import { PropTypes } from 'prop-types';

const Notification = ({ status = 'info', variant = 'major', message }) => {
	return variant === 'major' ? (
		<MajorContainer $status={status}>
			{status === 'success' ? <NotificationCheckmark /> : status === 'error' ? <NotificationInfo /> : <NotificationCoolEmote />}
			<p>{message}</p>
		</MajorContainer>
	) : (
		<MinorContainer>{message}</MinorContainer>
	);
};

Notification.propTypes = {
	message: PropTypes.string,
	variant: PropTypes.oneOf(['major', 'minor']),
	status: PropTypes.oneOf(['info', 'success', 'error']),
};

export default Notification;
