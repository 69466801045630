import styled from 'styled-components';
import { maxQuery } from '../../utilities';
import { StyledButton } from '../../ui/Button/styles';

export const Container = styled.footer`
	padding: 2.37rem 8.5rem 2.2rem;
	padding: 2.37rem clamp(1rem, -2.9894rem + 12.766vw, 8.5rem) 2.2rem;
	background: #070d19;
	color: #fff;

	.top {
		display: flex;
		gap: 2rem;
		justify-content: space-between;
		margin-bottom: 2rem;

		${maxQuery(1300)} {
			flex-direction: column;
			text-align: center;
		}

		h5 {
			font-weight: 600;
			font-size: 1.5rem;
			font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
			line-height: 150%;
			margin-bottom: 1rem;
			font-family: 'Urbanist', sans-serif;
			text-transform: capitalize;
			margin-top: 1rem;
		}
		a,
		span,
		button:not(${StyledButton}) {
			display: block;
			font-size: 1rem;
			font-size: clamp(0.875rem, 0.8056rem + 0.2222vw, 1rem);
			line-height: 150%;
			text-transform: capitalize;
			background-color: transparent;
			color: inherit;
			font-weight: 400;

			:not(:last-child) {
				margin-bottom: 1rem;
				margin-bottom: clamp(0.75rem, 0.6111rem + 0.4444vw, 1rem);
			}
			${maxQuery(1300)} {
				width: max-content;
				margin: 0 auto;
			}
		}

		.description {
			width: 35%;
			max-width: 35ch;

			${maxQuery(1300)} {
				max-width: 100%;
				width: 100%;
			}

			p {
				font-size: 1rem;
				font-size: clamp(0.8125rem, 0.7083rem + 0.3333vw, 1rem);
				line-height: 150%;
				margin: 0.63rem 0 1.5rem;
				color: #ccc;
			}

			${StyledButton} {
				width: 100%;
				justify-content: center;

				${maxQuery(1300)} {
					width: auto;
					margin: auto;
				}
			}
		}
		.contact {
			width: 20%;

			${maxQuery(1300)} {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	hr {
		border-color: rgba(255, 255, 255, 0.2);

		${maxQuery('xl')} {
			max-width: 50%;
			margin: auto;
			min-width: 161px;
		}
	}

	.bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 1.75rem;

		${maxQuery('xl')} {
			flex-direction: column;
			text-align: center;
		}

		a {
			font-size: 0.875rem;
		}

		.links-con {
			display: flex;
			gap: 1rem;
			justify-content: space-evenly;

			${maxQuery('xl')} {
				flex-direction: column;
				position: relative;
			}

			button {
				color: #fff;
				font-size: 0.875rem;
				font-weight: 400;
				text-transform: uppercase;
				padding: 0rem;
				background: transparent;
				border-radius: 0.125rem;
				width: max-content;
				margin: auto;

				:hover {
					text-decoration: underline;
				}
				:focus {
					outline: 1.5px solid #fff;
				}
			}
		}
		p {
			color: #f7f9fd;
			font-size: 0.875rem;
			line-height: 150%;
			text-align: center;
			color: #babcbe;

			${maxQuery('xl')} {
				padding: 2rem 0;
			}
		}
		.guide-con {
			display: flex;
			align-items: center;
			gap: 1rem;

			${maxQuery('xl')} {
				flex-direction: column;
			}
		}

		.socials-con {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			justify-content: space-evenly;

			${maxQuery('xl')} {
				gap: 1rem;
				justify-content: center;
				position: relative;
			}
		}
	}
`;
