import styled from 'styled-components';
import { HeroBg } from '../../../assets/svgs';
import { maxQuery } from '../../../utilities';
import { AboutUsHeroImg } from '../../../assets/images';

export const Container = styled.section`
	background: url(${HeroBg}) no-repeat #eff3fb;
	background-size: cover;
	text-align: center;
	overflow: hidden;

	> div:first-child {
		padding: 11.5rem 8.5rem 5rem;
		padding: 11.5rem clamp(2rem, -1.75rem + 12vw, 8.75rem) 5rem;
		color: #070d19;

		h1 {
			font-family: 'Sansation', 'Rethink Sans', sans-serif;
			font-size: 3.125rem;
			text-transform: capitalize;
			font-size: clamp(2.5rem, 2.1528rem + 1.1111vw, 3.125rem);
			line-height: 1.2;
			letter-spacing: -0.04rem;

			span {
				color: #4060ea;
			}
		}
		p {
			margin: 1.5rem auto 2rem;
			line-height: 160%;
			max-width: 44ch;
		}
		div {
			display: flex;
			gap: 1.5rem;
			gap: clamp(0.75rem, -0.375rem + 3vw, 1.5rem);
			justify-content: center;

			${maxQuery('550px')} {
				> * {
					flex: 1;
				}
				a button {
					width: 100%;
				}
				button {
					padding: 1rem 2.5rem;
					padding: clamp(0.8rem, 0.6rem + 0.64vw, 1rem) clamp(0.75rem, 0.05rem + 2.8vw, 2.5rem);
				}
			}
			${maxQuery('370px')} {
				flex-direction: column;
			}

			button {
				justify-content: center;
			}
		}
	}
	section {
		padding: 0 8.5rem 7rem;
		padding: 0 clamp(2rem, -1.75rem + 12vw, 8.75rem) 7rem;

		> div {
			display: flex;
			/* grid-template-columns: 1fr 1fr 1fr; */
			gap: 1.5rem;
			border-radius: 0.625rem;
			padding: 2.375rem;
			background: url(${AboutUsHeroImg}) center center;
			background-size: cover;
			width: fit-content;
			margin: auto;
			display: none;

			${maxQuery('lg')} {
				display: none;
				/* width: 100%;
				flex-direction: column; */
			}

			> div {
				background: linear-gradient(135deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
				backdrop-filter: blur(30px);
				-webkit-backdrop-filter: blur(30px);
				border-radius: 10px;
				/* border: 1px solid rgba(255, 255, 255, 0.18); */
				/* box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37); */
				max-width: 340px;
				width: 30%;
				color: #ffffff;
				text-align: center;
				padding: 4.5rem 2.25rem;
				flex: 1;

				/* ${maxQuery('lg')} {
					width: 100%;
					max-width: 100%;
				} */

				big {
					display: block;
					font-size: 4rem;
					font-size: clamp(2.5rem, 1.6667rem + 2.6667vw, 4rem);
					font-weight: 700;
					margin-bottom: 1.75rem;
					line-height: 120%;
				}
				h6 {
					font-family: 'Urbanist', sans-serif;
					line-height: 120%;
					font-size: 1.5rem;
					font-size: clamp(1rem, 0.7222rem + 0.8889vw, 1.5rem);
					font-weight: 600;
					margin-bottom: 0.625rem;
				}
				p {
					line-height: 120%;
					font-size: 1.25rem;
					font-size: clamp(0.825rem, 0.5889rem + 0.7556vw, 1.25rem);
				}
			}
		}
	}
`;
