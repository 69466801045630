import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 8rem;
`;

export const Nav = styled.nav`
	display: flex;
	/* gap: 0.5rem 1rem; */
	overflow: auto;
	color: #000;
	/* color: #ffffff; */
	/* background-color: #4060ea; */
	text-transform: capitalize;
	margin: 0 8.5rem 2.25rem;
	margin: 0 clamp(2rem, -1.75rem + 12vw, 8.75rem) 2.25rem;
	font-size: 1.125rem;
	font-size: clamp(0.825rem, 0.6583rem + 0.5333vw, 1.125rem);
	font-weight: 600;
	line-height: 1.2;

	a {
		min-width: max-content;
		padding: 0.75rem 1.5rem;
		/* padding: clamp(1.5rem, 1.1181rem + 1.2222vw, 2.1875rem) 0.75rem; */
		flex: 1;
		text-align: center;
		border-bottom: 2px solid transparent;
		border-radius: 4px;

		&.active {
			/* border-bottom-color: #4060ea; */
			color: #fff;
			background-color: #4060ea;
		}
	}
`;
