import { Container } from './styles';

const PrivacyPolicy = () => {
	return (
		<Container>
			<header>
				<h1>Privacy Policy</h1>
				<p>Last updated August 2024</p>
			</header>

			<section>
				<p>
					This privacy policy applies between you, the User of the Descinder Platform, and Descinder, the owner and provider of this service.
					Descinder values the privacy of your information and governs the use of any and all Data collected during your use of the Platform.
				</p>
				<br />
				<p>Please read this privacy policy alongside our Terms and Conditions, accessible at: Descinder Terms and Conditions.</p>
				<br />
				<p>In this privacy policy:</p>
				<ul className="pad-left">
					<li>“Data” refers to all information you submit to Descinder through the Platform or email.</li>
					<li>“Cookies” are small text files on your computer when using certain parts or features of the Platform.</li>
					<li>“Data Protection Laws” include relevant laws on personal Data processing, such as GDPR.</li>
					<li>
						“Descinder” or “us” refers to Descinder, the company and its subsidiaries, incorporated in England and Wales, registered at 22, Errol
						street, Middlesbrough, United Kingdom.
					</li>
					<li>
						“User” or “you” means any third party accessing the Platform, not employed by Descinder or engaged as a consultant providing services.
					</li>
				</ul>
				<br />
				<p>
					This privacy policy pertains solely to actions of Descinder and Users regarding the Platform, excluding other websites linked from this
					Platform. Descinder is the data controller as defined by Data Protection Laws.
				</p>
				<br />
				<p>
					We may collect various Data, including personal Data, such as name, email address, and other KYC details, through user interaction,
					from other sources, and automatically.
				</p>
				<br />
				<p>
					Your Data may be used for internal record-keeping, improving products/services, email transmission of marketing materials, and market
					research. We may use your Data for these purposes based on our legitimate interests.
				</p>
				<br />
				<p>
					We may share your Data with group companies, employees, agents, third-party service providers, third-party payment providers, and
					relevant authorities, all in accordance with this privacy policy.
				</p>
				<br />
				<p>
					We employ technical and organizational measures to secure your Data. Data retention follows the outlined purposes, and you have rights
					to access, correct, erase, restrict use, data portability, and object to processing.
				</p>
				<br />
				<p>
					Your Data may be transferred outside the European Economic Area with appropriate safeguards and procedures in place. Links to other
					websites are beyond our control, and our Cookies usage is in accordance with UK and EU Cookie Law.
				</p>
				<br />
				<p>
					This privacy policy outlines the type of data collected during the KYC process for users and registration procedures on the Descinder
					Platform:
				</p>
				<p> 1. Business (Start-up and SMEs):</p>
				<ul className="pad-left">
					<li>Users create an account, complete user registration, and profile (KYC).</li>
					<li>Create projects, assign tasks, and approve delivered tasks.</li>
				</ul>
				<br />
				<div className="pad-left">
					<p>KYC Information:</p>
					<ul className="pad-left">
						<li>Company’s Email Address</li>
						<li>Password</li>
						<li>Company’s Name</li>
						<li>Company Contact Number</li>
						<li>Company Registration Number</li>
						<li>Company Sector</li>
						<li>Current Company Size</li>
						<li>Company Logo (Optional)</li>
						<li>Company’s Website URL (Optional)</li>
						<li>Brief Company Introduction</li>
					</ul>
				</div>
				<br />
				<p>2. Early Career Individuals (Interns & Fresh Graduates):</p>
				<ul className="pad-left">
					<li>Users create an account, complete user registration/profile (KYC).</li>
					<li>Receive tasks and submit logs.</li>
				</ul>
				<br />
				<div className="pad-left">
					<p>KYC Information:</p>
					<ul className="pad-left">
						<li>Email Address</li>
						<li>Contact Number</li>
						<li>Password</li>
						<li>Applicant’s Avatar (Optional)</li>
						<li>Applicant’s Name</li>
						<li>Brief Introduction About Yourself</li>
						<li>Applicant Type - Intern / Fresh Graduate</li>
						<li>Highest Level of Education</li>
						<li>Area of Study</li>
						<li>Recent Institution Attended</li>
						<li>Institution ID Card</li>
						<li>Employee (Employer’s Name, Role/Position, Employment Duration)</li>
						<li>My Skills</li>
						<li>Interested Role</li>
						<li>English Proficiency Level</li>
						<li>Interested Sector (3 Max)</li>
						<li>Resume/CV</li>
					</ul>
				</div>
				<br />

				<p>
					At Descinder, we are committed to safeguarding the privacy of our users and ensuring a secure and trustworthy platform for
					collaborative endeavours between start-ups, SMEs, and interns. This Privacy Policy outlines the principles and practices governing the
					collection, use, and protection of user information on Descinder.
				</p>
				<br />
				<br />

				<h4>I. What Information Do We Collect?</h4>
				<p>
					We collect information voluntarily provided by users, encompassing content, communications, and other data when utilizing our Products.
					This includes details from account sign-ups, file submissions, and interactions with others. Transaction-related information, such as
					payment details, credit or debit card numbers, and billing/contact information, is also gathered.
				</p>
				<br />
				<h4>II. How is This Information Shared?</h4>
				<p>
					We employ the collected information to verify accounts, counteract harmful behavior, prevent spam, and uphold the integrity of our
					Products. This supports safety and security measures, including the investigation of suspicious activities or violations of our terms
					and policies.
				</p>
				<br />

				<h4>III. How Descinder Collects Data</h4>
				<p>
					All user-provided personal information is submitted voluntarily. Data collection methods include manual processes through our web
					application, utilizing forms for sign-ups and user communications. While we do not currently use Google Analytics or track device
					information, third-party services may be employed for potential future automatic data collection.
				</p>
				<br />

				<h4>IV. Ways Descinder Collects User Data</h4>
				<ul className="pad-left">
					<li>
						Manually: User data is primarily gathered through our web application via form submissions during sign-ups and user communications.
					</li>
					<li>
						Automatic: Though not currently in use, potential future utilization of third-party services like Google Analytics for tracking device
						and usage information is acknowledged.
					</li>
				</ul>
				<br />
				<h4>V. Data Received from Third Parties</h4>
				<p>
					We utilize third-party services like Stripe to process payments, involving the submission of payment information. Transaction details
					resulting from these processes are then transmitted to and stored by us.
				</p>
				<br />

				<h4>VI. Data Collected Automatically</h4>
				<p>
					While not currently implemented, third-party services like Google Analytics may be considered for potential future automatic collection
					of device and usage information. As of now, we do not use Google Analytics or track any device information.
				</p>
				<br />
				<p>
					This data is collected to facilitate user engagement, task assignment, and improve our services. We prioritize the security and
					confidentiality of this information, employing technical and organizational measures. Users have rights to access, correct, erase,
					restrict use, data portability, and object to processing. Data may be shared as outlined in this policy.
				</p>
				<br />
				<br />
				<p>
					Descinder reserves the right to change this privacy policy, with any alterations immediately posted on the Platform. You are deemed to
					accept the terms upon your first use of the Platform after changes. For inquiries or to contact Descinder, email info@descinder.com.
				</p>
			</section>
			<section>
				<h4>RIGHTS, OBLIGATIONS AND LIMITS</h4>
				<br />
				<p>
					Users on Descinder have the right to fair employment practices (this in no way creates an employment between Descinder and the user),
					which are explicitly defined to ensure transparency and equality. This includes the right to create an account; however, Descinder
					reserves the right to restrict or ban an account upon the detection of bad practices. Account restrictions or bans will be enforced
					based on predefined criteria, which will be communicated to users, ensuring a transparent process.
				</p>
				<br />
				<p>
					To create an account, users must provide accurate data, complete the registration process, and undergo Know Your Customer (KYC)
					verification. The purpose of collecting user data is to ensure compatibility in matchups with companies and start-ups. The data
					provided will be processed in a manner that complies with the relevant General Data Protection Regulation (GDPR) standards.
				</p>
				<br />
				<p>
					Users maintain the right to withdraw consent for data processing at any time. However, it's essential to note that withdrawing consent
					may impact the functionality of certain features or services on Descinder. Descinder is committed to processing user data
					transparently, ensuring adherence to GDPR standards in terms of purpose, methods, and safeguards. We prioritize user privacy and aim to
					provide a secure and trustworthy platform for collaborative professional experiences.
				</p>
				<br />
				<p>
					By using Descinder, users acknowledge and agree to the specified rights, obligations, and limits outlined in this document. We remain
					dedicated to fostering a fair and equitable employment environment while upholding the highest standards of data protection and privacy
					compliance.
				</p>
				<br />
			</section>
			<section>
				<h4>DISCLAIMER LIMIT OF LIABILITY AND TERMINATION</h4>
				<br />
				<p>
					Either the user or Descinder may terminate this agreement at any time by providing notice to the other party. Termination results in
					the loss of the user's right to access or use the Services.
				</p>
				<br />
				<p>The following shall survive termination:</p>
				<ul>
					<li>Our rights to use and disclose your feedback, in compliance with relevant GDPR regulations.</li>
					<li>Members' and/or Visitors’ rights to further re-share content and information shared through the Services.</li>
					<li>Any amounts owed by either party prior to termination remain owed after termination.</li>
				</ul>
				<br />
				<p>
					To initiate the termination process, users must follow the guidelines outlined in the termination section of this agreement. The
					termination process includes providing notice and may involve additional steps, which will be communicated to users for clarity.
				</p>
				<br />
				<p>
					Descinder is committed to handling user data with the utmost care, even after termination. We adhere to GDPR regulations regarding the
					use and disclosure of user data. Users can refer to our Privacy Policy for comprehensive information on data processing.
				</p>
				<br />
				<p>
					For instructions on closing your account or any queries related to termination, users can contact our dedicated support team at
					<a href="mailto:info@descinder.com">info@descinder.com</a>.
				</p>
			</section>
			<section>
				<h4>GOVERNING LAW AND DISPUTE RESOLUTION</h4>
				<br />
				<p>
					In the unlikely event we end up in a legal dispute, depending on where the user lives, the user and Descinder agree to resolve it in
					United Kingdom courts using United Kingdom law, or in your local courts using local law. If you live in the Designated Country, the
					laws of the United Kingdom govern all claims related to Descinder's provision of the Services, but this shall not deprive the user of
					the mandatory consumer protections under the law of the country to which we direct the user’s Services where the user has habitual
					residence. With respect to jurisdiction, the user and Descinder agree to choose the courts of the country to which we direct the user’s
					Services where the user has habitual residence for all disputes arising out of or relating to this User Agreement, or in the
					alternative, the user may choose the responsible court in the United Kingdom. For others outside of the Designated Country, including
					those who live outside of the United Kingdom: You and Descinder agree that the laws of the United Kingdom, U.K., excluding its conflict
					of laws rules, shall exclusively govern any dispute relating to these terms and/or the Services. The user and Descinder both agree that
					all claims and disputes can be litigated only in the courts in the United Kingdom, or relevant Arbitration Tribunal, and the user and
					Descinder each agree to personal jurisdiction in those courts.
				</p>
			</section>
		</Container>
	);
};
export default PrivacyPolicy;
