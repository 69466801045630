import styled from 'styled-components';
import { maxQuery } from '../../../utilities';
import { StyledButton } from '../../../ui/Button/styles';

export const Container = styled.div`
	position: fixed;
	inset: 0;
	z-index: 99;
	display: grid;
	place-items: center;

	.modal {
		border-radius: 0.25rem;
		box-shadow: -9px 19px 40px 0px rgba(0, 0, 0, 0.02);

		position: relative;
		z-index: 2;
		width: calc(100vw - 4rem);
		width: calc(100vw - clamp(2rem, 0.8889rem + 3.5556vw, 4rem));

		max-width: 1190px;
		height: calc(100vh - 4rem);
		height: calc(100vh - clamp(2rem, 0.8889rem + 3.5556vw, 4rem));

		max-height: 780px;
		display: flex;
		background: #070d19;
		overflow: hidden;

		${maxQuery('lg')} {
			flex-direction: column-reverse;
		}

		> svg {
			position: absolute;
			right: 1rem;
			top: 1rem;
			width: 1.5rem;
			width: clamp(1.3rem, 1.1889rem + 0.3556vw, 1.5rem);

			cursor: pointer;

			:active {
				outline: none;
			}

			path {
				fill: #000;
			}
		}

		aside {
			width: 22rem;
			display: grid;
			padding: 5rem 0;

			${maxQuery('lg')} {
				display: flex;
				width: 100%;
				padding: 0;
				border-top: 1px solid #070d19;
			}
			${maxQuery('sm')} {
				display: block;
			}

			button {
				display: block;
				width: 100%;
				background: transparent;
				color: #fff;
				text-align: left;
				padding: 3.5rem;

				${maxQuery('lg')} {
					padding: 0.75rem;
					text-align: center;
				}

				&.active {
					background: #fff;

					span {
						color: #070d19 !important;
					}
				}

				span {
					display: block;

					:first-child {
						color: rgba(255, 255, 255, 0.6);
						font-size: 0.875rem;
						font-size: clamp(0.75rem, 0.6835rem + 0.2128vw, 0.875rem);

						line-height: 135%;
						font-weight: 500;
					}
					:last-child {
						margin-top: 0.2rem;
						color: #fff;
						font-size: 1.5rem;
						font-size: clamp(1rem, 0.734rem + 0.8511vw, 1.5rem);

						font-weight: 700;
						text-transform: uppercase;
					}
				}
			}
			> div {
				margin-top: auto;

				${maxQuery('lg')} {
					display: none;
				}

				span {
					display: block;
					text-align: center;
					color: #fff;
					font-size: 0.875rem;
					line-height: 135%;
					margin: 0.6rem 0 1rem;
				}
				> svg {
					margin: auto;
					display: block;
				}
			}
			.socials-con {
				display: flex;
				gap: 0.9rem;
				justify-content: center;
				padding: 0.6rem 0;
				position: relative;

				::before {
					content: '';
					position: absolute;
					background: rgba(255, 255, 255, 0.5);
					height: 1px;
					bottom: 0;
					left: 50%;
					width: 60%;
					transform: translateX(-50%);
					min-width: 160px;
					max-width: 300px;
				}
			}
		}
		.main {
			border-radius: 0.25rem;
			background: #fff;
			flex: 1;
			padding: 3rem 4rem;
			padding: clamp(2rem, 1.4444rem + 1.7778vw, 3rem) clamp(1rem, -0.6667rem + 5.3333vw, 4rem);

			overflow: auto;

			${maxQuery('lg')} {
				border-radius: 0;
			}
		}
		.header {
			border-bottom: 1px solid #ebebeb;
			padding-bottom: 1.5rem;

			h3 {
				color: #070d19;
				font-size: 2rem;
				font-size: clamp(1.5rem, 1.2222rem + 0.8889vw, 2rem);

				margin-bottom: 0.5rem;
			}
			> p {
				color: #070d19;
				font-size: 1rem;
				font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);

				line-height: 135%;
			}
		}

		form {
			margin-top: 2rem;

			&.introductory-form {
				width: 25rem;
				max-width: 100%;

				.group {
					grid-template-columns: 1fr;
				}
				.btn-group {
					display: flex;
					gap: 1rem;
					margin-top: 1.5rem;

					${StyledButton} {
						flex: 1;
						justify-content: center;
					}
				}
			}

			.group {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1rem;

				${maxQuery('lg')} {
					grid-template-columns: 1fr;
				}
			}
			.file-group {
				display: flex;
				border: 1px solid #dadada;
				border-radius: 0.1875rem;
				font-size: 0.875rem;
				line-height: 130%;
				cursor: pointer;
				position: relative;

				div {
					padding: 1.5rem;
					display: flex;
					align-items: center;
					justify-content: center;

					:last-child {
						border-left: 1px solid #dadada;
					}

					p {
						display: -webkit-box;
						overflow: hidden;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
				}

				input {
					position: absolute;
					inset: 0;
					opacity: 0;
					z-index: -1;
				}
			}
			.terms {
				display: flex;
				gap: 0.5rem;
				color: #1e1e1e;
				font-size: 0.75rem;
				line-height: 160%;
				margin: 1rem 0 1.5rem;

				button {
					color: #4060ea;
					font-weight: 600;
					background-color: transparent;
				}
			}
		}
		${StyledButton} {
			${maxQuery('lg')} {
				width: 100%;
				justify-content: center;
			}
		}
	}
	.backdrop {
		opacity: 0.82;
		background: #000;
		position: absolute;
		inset: 0;
	}
`;
