import { Container } from './styles';

const InputGroup = ({ label, value = '', onChange, placeholder, variant = 'input', type = 'text', name, ...rest }) => {
	return (
		<Container>
			<label htmlFor={name}>{label}</label>

			{variant === 'input' ? (
				<input type={type} placeholder={placeholder} name={name} value={value} onChange={onChange} {...rest} />
			) : variant === 'textarea' ? (
				<textarea rows={3} placeholder={placeholder} name={name} value={value} onChange={onChange} {...rest} />
			) : null}
		</Container>
	);
};

export default InputGroup;
