import { useState } from 'react';
import { TopRightArrow } from '../../assets/svgs';
import { OneStopImage } from '../../assets/images';
import { Button } from '../../ui';
import { Container } from './styles';

const navList = [
	{
		title: 'Add Supervisors',
		body:
			'Our program provides you with opportunities to apply your skills, learn from industry experts, and contribute to impactful work, setting the stage for a successful career.',
	},
	{ title: 'Log Report', body: 'Log all completed tasks to track your progress and project achievements.' },
	{ title: 'View Tasks', body: 'Receive notifications for all tasks assigned to you on each created project.' },
	{
		title: 'Add-Ons',
		body: 'Enjoy the benefits of a variety of add-ons, including CV, cover letter, LinkedIn optimization, and future perks.',
	},
];
const OneStopSection = () => {
	const [state, setState] = useState(navList[0]);

	return (
		<Container>
			<div>
				<div className="img-con">
					<a href="https://app.descinder.com/signup/as-intern">
						<TopRightArrow />
					</a>
					<img src={OneStopImage} alt="" loading="lazy" />
				</div>
				<div>
					<h4>Interns & fresh Graduate</h4>
					<h3>Your Gateway to Real-World Experience</h3>
					<div className="nav">
						{navList.map((item) => (
							<div key={item.title} className={item.title === state?.title ? 'active' : ''} onClick={() => setState(item)}>
								{item.title}
							</div>
						))}
					</div>
					<p>{state?.body}</p>
					<a href="https://app.descinder.com/signup/as-intern">
						<Button>GET STARTED</Button>
					</a>
				</div>
			</div>
		</Container>
	);
};

export default OneStopSection;
