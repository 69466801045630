import styled from 'styled-components';

export const Container = styled.div`
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;

	> div {
		:not(:first-child) {
			border-top: 1px solid #1a1a1a;
		}

		&.active {
			> button {
				.minus {
					display: block;
				}
				.plus {
					display: none;
				}
			}
			div {
				max-height: 300px;
			}
		}
		> button {
			padding: 1.5rem 0;
			padding: clamp(1rem, 0.1667rem + 1.4815vw, 1.5rem) 0;

			color: #1a1a1a;
			font-weight: 700;
			font-size: 1.5rem;
			font-size: clamp(0.875rem, 0.5426rem + 1.0638vw, 1.5rem);

			line-height: 130%;
			letter-spacing: -0.03rem;
			text-transform: uppercase;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			background-color: transparent;
			width: 100%;
			font-family: inherit;
			text-align: left;

			.minus {
				display: none;
			}
		}
		div {
			max-height: 0px;
			overflow: hidden;
			transition: max-height 0.3s linear;

			p {
				color: #4f4f4f;
				font-size: 1.25rem;
				font-size: clamp(0.75rem, 0.484rem + 0.8511vw, 1.25rem);

				line-height: 150%;
				margin-bottom: 0.5rem;
			}
			button {
				margin-bottom: 1.5rem;
				margin-bottom: clamp(1rem, 0.1667rem + 1.4815vw, 1.5rem);
			}
		}
	}
`;
