export const INTERN_PAYMENT_PACKAGE = {
	commitment_package: [
		{
			has_promo: false,
			key: '3 months commitment fee',
			title: '3 Months',
			body: 'Commit to your 3 months internship experience',
			// old_price: 99.99,
			// price: 49.99,
			price: 0, // 99.99,
			duration: '3 Months',
			link: 'https://app.descinder.com/signup/as-intern',
			video_link: '',
			perks: ['3 Months Internship', 'Add Supervisors', 'Log Reports'],
		},
		// {
		// 	key: 'summer internship',
		// 	title: 'SUMMER INTERNSHIP',
		// 	body: 'Commit to your  10 -12 Weeks internship experience',
		// 	old_price: 124.99,
		// 	price: 74.99,
		// 	duration: '12 Weeks',
		// 	link: 'https://app.descinder.com/signup/as-intern',
		// 	perks: ['12 Weeks Internship', 'Add Supervisors', 'Log Reports'],
		// },
		{
			has_promo: false,
			key: '6 months commitment fee',
			title: '6 Months',
			body: 'Commit to your 6 months internship experience',
			// old_price: 149.99,
			// price: 99.99,
			price: 0, // 149.99,
			duration: '6 Months',
			link: 'https://app.descinder.com/signup/as-intern',
			video_link: '',
			perks: ['6 Months Internship', 'Add Supervisors', 'Log Reports'],
		},
	],
	add_ons: [
		{ key: 'combo package', title: 'Combo Package', body: 'Cv, Cover Letter & Linkedin Optimaization', price: 50, is_compound: true },
		{ key: 'cv/resume', title: 'Cv/Resume', body: 'We create internship-based Resume/Cv', price: 25, is_compound: false },
		{ key: 'cover letter', title: 'Cover Letter', body: 'We create internship-based cover letters.', price: 15, is_compound: false },
		{ key: 'linkedin optimization', title: 'LinkedIn OptZ', body: 'Optimize your Linkedin Profile', price: 20, is_compound: false },
	],
};

export const STARTUP_PAYMENT_PACKAGE = [
	{
		has_promo: true,
		key: 'startup_quarter_pack',
		title: 'Quarter Pack',
		old_price: 250,
		// price: 149.99,
		price: 0, // 75,
		refund_rate: '50% Refunded',
		duration: '3 Months',
		link: 'https://app.descinder.com/signup/as-startup',
		video_link: 'https://youtu.be/3WsXuIYzhZA',
		perks: ['Create Project and Task', 'Add Up to 5 Resources', '3 Months Lifecycle'],
	},
	{
		has_promo: true,
		key: 'startup_half_pack',
		title: 'Half Pack',
		old_price: 350,
		// price: 249.99,
		price: 0, // 125,
		refund_rate: '50% Refunded',
		duration: '6 Months',
		link: 'https://app.descinder.com/signup/as-startup',
		video_link: 'https://youtu.be/-fGO7xVbCRQ',
		perks: ['Create Projects and Task', 'Add up to  8 Resources', '6 Months Lifecycle'],
	},
	{
		has_promo: true,
		key: 'startup_year_pack',
		title: 'Year Pack',
		old_price: 500,
		// price: 349.99,
		price: 0, // 200,
		refund_rate: '50% Refunded',
		duration: '1 Year',
		link: 'https://app.descinder.com/signup/as-startup',
		video_link: ' https://youtu.be/POXx61cXBUg',
		perks: ['Create Projects and Task', 'Add up to 10 Resources', '12 Months Lifecycle'],
	},
];
