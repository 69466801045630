import styled from 'styled-components';

export const Container = styled.div`
	border-radius: 1.60525rem;
	border: 0.917px solid #e2e2e2;
	background: #fff;

	img,
	svg {
		width: 100%;
		height: auto;
	}

	div {
		padding: 1.3rem 4.3rem 3.4rem;
		padding: 1.3rem clamp(1.5rem, -3.1667rem + 8.2963vw, 4.3rem) clamp(1.5rem, -1.6667rem + 5.6296vw, 3.4rem);

		h3 {
			font-size: 1.875rem;
			font-size: clamp(1.6rem, 1.3399rem + 0.5946vw, 1.875rem);

			line-height: 106%;
			letter-spacing: -0.01813rem;
			margin-bottom: 0.8rem;
		}
		p {
			line-height: 138%;
		}
	}
`;
