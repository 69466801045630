import { useContext } from 'react';
import { CloseMenu } from '../../../assets/svgs';
import { Container } from './styles';
import { ModalContext } from '../../../context';

const TermsAndConditions = () => {
	const { setModals } = useContext(ModalContext);

	return (
		<Container>
			<div className="modal">
				<CloseMenu tabIndex={0} onClick={() => setModals((prev) => ({ ...prev, showTerms: false }))} />

				<h3>Terms & Conditions</h3>
				<p>
					Welcome to Descinder! These Terms and Conditions ("Terms") govern your use of the Descinder platform ("Platform"). By accessing or
					using the Platform, you agree to comply with these Terms. Please read them carefully.
				</p>
				<h5>Acceptance of Terms</h5>
				<p>
					By using the Platform, you acknowledge that you have read, understood, and agree to these Terms. If you do not agree with any part of
					these Terms, you may not use the Platform.
				</p>
				<h5>Use of the Platform</h5>
				<p>
					The Platform connects individuals seeking their first-time experiences with startups aiming to develop products or projects. You may
					use the Platform to find opportunities, connect with startups, and participate in various projects.
				</p>
				<h5>User Responsibilities</h5>
				<p>
					You agree to provide accurate and truthful information on your profile and in your interactions with other users. You are responsible
					for maintaining the confidentiality of your account information and are liable for all activities that occur under your account.
				</p>
				<h5>Intellectual Property</h5>
				<p>
					Any content you submit to the Platform remains your property. By submitting content, you grant Descinder a non-exclusive, royalty-free,
					worldwide license to use, reproduce, modify, and distribute your content for the purpose of operating the Platform.
				</p>
				<h5>Privacy</h5>
				<p>Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>
				<h5>Limitation of Liability</h5>
				<p>
					Descinder does not guarantee the accuracy, reliability, or completeness of any content on the Platform. We are not responsible for any
					interactions or transactions between users, and we do not endorse any startup or individual.
				</p>
				<h5>Termination</h5>
				<p>
					We reserve the right to terminate or suspend your account and access to the Platform at our discretion, without notice, if you violate
					these Terms.
				</p>
				<br />
				<h5>Privacy Policy</h5>
				<h5>Information We Collect</h5>
				<p>
					We collect personal and non-personal information when you use our Platform. This may include your name, email address, profile
					information, and usage data.
				</p>
				<h5>How We Use Your Information</h5>
				<p>
					We use your information to provide and improve our services, connect you with startups, and communicate with you. Your data may be used
					for analytics, research, and marketing purposes, but will not be shared with third parties without your consent.
				</p>
				<h5>Data Security</h5>
				<p>
					We implement technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or
					destruction.
				</p>
				<h5>Cookies and Tracking</h5>
				<p>
					We use cookies and similar technologies to enhance your experience on the Platform and collect information about your usage patterns.
				</p>
				<h5>Third-Party Links</h5>
				<p>
					Our Platform may contain links to third-party websites. We are not responsible for the content or privacy practices of these sites.
				</p>
				<h5>Your Choices</h5>
				<p>
					You can update your account information and communication preferences at any time. You may also delete your account, subject to the
					retention of certain data for legal or operational purposes.
				</p>
				<h5>Contact Us</h5>
				<p>
					If you have any questions or concerns about your privacy or these policies, please contact us at{' '}
					<a href="mailto:info@descinder.com">info@descinder.com</a>. This is a simplified example and should not be considered a legally binding
					document. It's important to consult legal professionals when drafting your actual Terms and Conditions and Privacy Policy to ensure
					they accurately reflect your platform's specific operations and legal requirements.
				</p>
			</div>
			<div className="backdrop"></div>
		</Container>
	);
};

export default TermsAndConditions;
