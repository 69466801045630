import styled from 'styled-components';
import { ProductBg } from '../../assets/svgs';
import { maxQuery } from '../../utilities';

export const Container = styled.section`
	padding: 7.5rem 8.5rem 7.5rem;
	padding: clamp(2rem, -0.9255rem + 9.3617vw, 7.5rem) clamp(2rem, -1.75rem + 12vw, 8.75rem) clamp(2rem, -0.9255rem + 9.3617vw, 7.5rem);

	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 3.75rem;
	gap: clamp(1.5rem, -2.25rem + 6.6667vw, 3.75rem);

	background: url(${ProductBg}) no-repeat #f7f9fd;
	background-size: cover;

	> div:first-child {
		width: 32.625rem;
		max-width: 45%;
		flex-shrink: 0;

		${maxQuery('md')} {
			display: none;
		}
	}

	> div:last-child {
		b {
			color: #4060ea;
			font-size: 1.5rem;
			font-size: clamp(0.8125rem, 0.4468rem + 1.1702vw, 1.5rem);

			font-weight: 700;
			line-height: 120%;
			text-transform: uppercase;
		}
		h2 {
			color: #070d19;
			font-family: 'Montserrat', sans-serif;
			font-size: 3.5rem;
			font-size: clamp(1.7rem, 0.8191rem + 2.9787vw, 3.5rem);

			font-weight: 800;
			line-height: 100%;
			text-transform: uppercase;
			margin: 1.5rem 0 1rem;
			margin: clamp(0.8rem, -0.3667rem + 2.0741vw, 1.5rem) 0 clamp(0.6rem, -0.0667rem + 1.1852vw, 1rem);
		}
	}
`;
