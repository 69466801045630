import styled from 'styled-components';

export const Container = styled.section`
	padding: 4rem 8.5rem 4rem;
	padding: clamp(3.5rem, 3.234rem + 0.8511vw, 4rem) clamp(2rem, -1.75rem + 12vw, 8.75rem) clamp(3.5rem, 3.234rem + 0.8511vw, 4rem);

	h3 {
		color: #070d19;
		font-size: 3.125rem;
		font-size: clamp(2rem, 1.4016rem + 1.9149vw, 3.125rem);

		line-height: 130%;
		text-align: center;
	}
	h3 + p {
		text-align: center;
		margin: 0 auto 3.125rem;
		max-width: 78ch;
		color: #333;
		font-size: 1rem;
		font-size: clamp(0.75rem, 0.617rem + 0.4255vw, 1rem);
		line-height: 150%;
	}

	.faq-con {
		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		.question {
			background-color: #fafbfe;
			padding: 1rem 2rem;
			display: flex;
			align-items: center;
			font-size: 1.125rem;
			font-size: clamp(1rem, 0.9306rem + 0.2222vw, 1.125rem);
			font-weight: 700;
			line-height: 200%;
			text-transform: uppercase;
			cursor: pointer;

			svg {
				margin-right: 2rem;
				height: auto;
				width: 18px;
			}
		}
		.answer {
			margin-top: 1rem;
			padding: 0 3.5rem 0 6rem;

			&.first {
				button:first-child {
					font-weight: 600;
					color: #4060ea;
				}
				p:first-child {
					display: block;
				}
			}
			&.second {
				button:nth-child(2) {
					font-weight: 600;
					color: #4060ea;
				}
				p:nth-child(2) {
					display: block;
				}
			}

			.control {
				display: flex;
				gap: 1rem;
				margin-bottom: 0.625rem;

				button {
					background: transparent;
					color: #7188ef;
					font-size: 1rem;
					line-height: 120%;
					letter-spacing: 0.04em;
					font-weight: 400;
				}
			}

			p {
				display: none;
				letter-spacing: 0.04em;
				line-height: 170%;
			}
			a {
				color: #4060ea;
			}
		}
		svg {
		}
		.minus,
		.answer {
			display: none;
		}
		&.active {
			.minus,
			.answer {
				display: block;
			}
			.plus {
				display: none;
			}
		}
	}
`;
