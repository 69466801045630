import { useState } from 'react';
import { Button } from '../../ui';
import { Container } from './styles';
import { HOW_INTERNSHIP_WORKS, HOW_STARTUP_WORKS } from '../../constants';

const HowItWorks = () => {
	const [control, setControl] = useState('startup');

	const HOW_IT_WORKS = control === 'startup' ? HOW_STARTUP_WORKS : HOW_INTERNSHIP_WORKS;

	return (
		<Container id="works">
			<div className="controls">
				<Button className={control === 'startup' ? 'active' : ''} onClick={() => setControl('startup')}>
					Start-up & SMEs
				</Button>
				<Button className={control === 'intern' ? 'active' : ''} onClick={() => setControl('intern')}>
					Interns & Graduates
				</Button>
			</div>

			<h3>How it works</h3>
			<p>{HOW_IT_WORKS.description}</p>

			<div className="content">
				{HOW_IT_WORKS.workings.map(({ title, body, icon }, index) => (
					<div key={title}>
						<big>{index + 1}</big>
						{icon}
						<h5>{title}</h5>
						<p>{body}</p>
					</div>
				))}
			</div>
		</Container>
	);
};

export default HowItWorks;
