import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const UseAnalytics = () => {
	const TRACKING_ID = 'G-TNF49X606J';
	const { pathname } = useLocation();

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			// Disable auto page view sending
			window.gtag('config', TRACKING_ID, {
				send_page_view: false,
			});

			// Send analytics on every page load
			window.gtag('config', TRACKING_ID, {
				page_title: window.location.pathname,
			});
		}
	}, [pathname]);

	return null;
};

export default UseAnalytics;
