import { EmpowerSection, ContactUs, Footer } from '../../components';
import CoreValues from './CoreValues';
import HeroSection from './HeroSection';
import { Container } from './styles';

const AboutUs = () => {
	return (
		<Container>
			<HeroSection />
			<CoreValues />
			<EmpowerSection />
			<ContactUs />
			<Footer />
		</Container>
	);
};
export default AboutUs;
