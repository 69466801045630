import styled from 'styled-components';

export const Container = styled.div`
	> header {
		margin-bottom: 2.25rem;
		padding: 0 8.5rem;
		padding: 0 clamp(2rem, -1.75rem + 12vw, 8.75rem);

		h1 {
			color: #4060ea;
			text-transform: capitalize;
			font-size: 3rem;
			font-size: clamp(1.5rem, 1rem + 2.2857vw, 3rem);
			line-height: 1.1;
		}
		p {
			font-weight: 500;
			font-size: 1rem;
			line-height: 1.3;
			margin-top: 0.5rem;
		}
	}
	section {
		padding: 3.125rem 8.5rem;
		padding: clamp(1rem, -0.1806rem + 3.7778vw, 3.125rem) clamp(2rem, -1.75rem + 12vw, 8.75rem);

		&.definition {
			background-color: #fafbfe;
		}

		h4 {
			font-weight: 700;
			font-size: 1.25rem;
			font-size: clamp(1.125rem, 1.0556rem + 0.2222vw, 1.25rem);
			line-height: 1.6;
		}
		p {
			font-size: 1.25rem;
			font-size: clamp(1rem, 0.8611rem + 0.4444vw, 1.25rem);
			line-height: 1.6;

			&.pad-left {
				padding-left: 1rem;
			}
		}
		a {
			color: #4060ea;
			text-decoration: underline;
		}
	}
`;
