import { useRef } from 'react';
import { LogoDark, HamburgerMenu, LogoLight, CloseMenu } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Container } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Approutes } from '../../../constants';

const Header = () => {
	const headerRef = useRef();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const openMobileNav = () => headerRef.current?.classList.add('active');
	const closeMobileNav = () => headerRef.current?.classList.remove('active');

	return (
		<Container ref={headerRef}>
			<div>
				<Link className="logo-con" to={Approutes.home}>
					<LogoDark />
					<LogoLight onClick={closeMobileNav} />
				</Link>

				<div className="control-con">
					<HamburgerMenu tabIndex={0} onClick={openMobileNav} />
					<CloseMenu className="close" tabIndex={0} onClick={closeMobileNav} />
				</div>

				<nav>
					<ul>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#why')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#why')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								Why Us
							</button>
						</li>
						<li>
							<Link to={Approutes.about_us}>About Us</Link>
						</li>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#works')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#works')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								How It Works
							</button>
						</li>
						<li>
							<button
								onClick={() => {
									closeMobileNav();
									if (pathname !== Approutes.home) {
										navigate(Approutes.home);

										setTimeout(() => {
											document.querySelector('#contact')?.scrollIntoView({ behavior: 'smooth' });
										}, 100);
									} else {
										document.querySelector('#contact')?.scrollIntoView({ behavior: 'smooth' });
									}
								}}
							>
								Contact Us
							</button>
						</li>
					</ul>
				</nav>

				<div className="btn-group">
					<a href="https://app.descinder.com/login">
						<Button variant="secondary">Sign In</Button>
					</a>
					<a href="https://app.descinder.com/signup">
						<Button>Get Started</Button>
					</a>
				</div>
			</div>
		</Container>
	);
};

export default Header;
